.container {
    width: 100%;
    background-color: #222;
    height: 90vh;
}

.absolute {
    
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    
    top: 120px;
    
    
    
  }

  .abImg {
    position: absolute;
    height: 90vh;
    opacity: 0.7;
    width: 100%;
    top: 0;
  }
  
  .abImg > img {
    width: 100%;
    height:100%;
    
    object-fit: cover;
  }

  .left {
    margin-left: 18vw;
  }

  .left> h2 {
    font-size: 53px;
    width: 60%;
    color: white;
    font-weight: bold;
    text-align: left;
    
  }

  .left > p {
    width: 60%;
    color: white;
    text-align: left;
    
    font-size: 20px;

}

 .valButtonAb {
  margin: 25px 0;
  color: white;
  width: 25%;
  background: transparent;
  border: 2px white solid;
  font-weight: 500;
  padding: 0.5vh 0.2vw;
  font-size: 15px;
  cursor: pointer;
  transition: 180ms all;
  border-radius: 3px;}

.valButtonAb:hover {
  background-color: #fff;
  color: #444;
}

.stars {
  margin-top: 0.7vh;
  
 
}

.star {
  color: goldenrod;
 
}
@media only screen and (max-width: 1187px) {

  
  .abImg {
    
    height: 450px;
    
 
  }

  .container {
   
    height: 450px;
}

    
    .absolute {
      width: 100%;
      align-items: center;
     flex-direction: column;
      

        top: 120px;
        left: 0;
        
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       width: 100%;
       margin-left: 0;
      }

      .left> p {
        margin-top: 10px;
        font-size: 17px;
        text-align: center;
        display: none;
      }

      .left > h2 {
        text-align: center;
        font-size: 36px;
        width: 70%;

      }

      .stars {
          margin-left: 0;
         

      }
      .right {
        display: none;
      }

    

    }

    @media only screen and (max-width: 1067px) {

      .absolute {
    
  
        top: 120px;
         
        
          
        }


    }


    @media only screen and (max-width: 738px) {
      .container {
   
        height: 310px;
      }
    
      .abImg {
        height: 310px;
    
      }

      .stars {
        margin-left: 0;
        display: none;

        

    }

    .absolute {
    
  
      top: 100px;
       
      
        
      }



      .left > h2 {
        text-align: center;
        font-size: 24px;
        width: 70%;

      }

      .valButtonAb {
       
        font-size: 11px;
        
      }



    }


  
    @media only screen and (min-width: 1700px) {

      .left {
        margin-left: 23vw;
      }

      .absolute {
    
  
        top: 140px;
         
        
          
        }


    }
