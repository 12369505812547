.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #faf9f6;
    padding-bottom: 5vh;
  }
  
  .wrapper {
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: 65%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #faf9f6;
    box-shadow: 0;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
   
  }


  
  .row {
    height: auto;
    display: flex;
    width: 100%;
    background: #fff;
  }


  
  .left {
    width: 100%;
    height: 640px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  
  .right {
    width: 100%;
    height: 640px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  
  }

  
  
  .desc {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    gap: 1rem;
    height: 100%;
  
  }
  
  .desc > h3 {
    text-align: center;
    font-size: 27px;
    font-weight: 600;
    
  }
  
  .desc > p {
    margin-top: 4vh;
    font-size: 17px;
    text-align: center;
    width: 50%;
    margin-bottom: 2vh;
    padding-bottom: 2vh;
    color: #444;
  }












 .img {
    object-fit: cover;
   
    width: 100%;
    height: 100%;
   
   
  }


.desc.appear{
  transform: translateY(0) !important;
 
  opacity: 1 !important;

  
}
  
  .custom-animated-text {
    margin-top: 4vh;
    font-size: 17px;
    text-align: center;
    width: 60%;
    margin-bottom: 2vh;
    padding-bottom: 2vh;
    color: #444;
  }
  
  .bouton {
    background: transparent;
    color: #444;
    border: 2px #444 solid;
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: 180ms all;
    border-radius: 3px;
    margin-bottom: 2vh;
  }
  
  .bouton:hover {
    background-color: #737373;
    color: #fff;
  }
  
  .img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }
  

  @media screen and (max-width: 1187px) {
    .left {
      height: 500px;
    }
  
    .right {
      height: 500px;
    }
  
    .desc > p {
     display: none;
    }
  
    .desc > h3 {
      font-size: 17px;
      margin-top: 1vh;
    }
  
    .bouton {
      font-size: 15px;
      padding: 0.2vh 0.2vw;
    }
  }
  
  @media only screen and (max-width: 1008px) {
    .desc > p {
      display: none;
    }
  
    .desc > h3 {
      font-size: 15px;
    }
  
    .wrapper {
      width: 85%;
    }
  
    .left {
      height: 400px;
    }
  
    .right {
      height: 400px;
    }
  
    .bouton {
      margin-top: 0.5vh;
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 608px) {

    .left {
      height: 250px;
    }
  
    .right {
      height: 250px;
    }

    .desc > h3 {
      font-size: 15px;
      width: 70%;
    }


  }

  @media only screen and (max-width: 308px) {

    .left {
      height: 200px;
    }
  
    .right {
      height: 200px;
    }
    .desc > h3 {
      font-size: 13px;
      width: 70%;
    }



  }
  