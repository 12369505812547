/* list property */

.contactForm {
   
   
    height: 100vh;
    width: 100%;
  
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

   
    padding-top: 15vh !important;

    background-position: center;

   
    
}

.contactFormWrapper {
    
   
    width:  33%;
    height: auto;
    border-radius: 20px;
    display: flex;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    flex-direction: column;
    justify-content: center;
    padding: 1.5vh 0.5vw;
    align-items: center;
    background-color: #fff;
    position: relative;
    margin: 17vh 0 17vh 0;
    padding: 7vh 0;
}

.contactFormWrapper>h2 {
    text-align: center;
  
    margin-bottom: 0.5rem;
    font-size: 35px;
}

.contactFormWrapper> p {
    font-size:  17px;
}

.contactFormWrapper>form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding-top: 1vh;
    
}

.contactFormWrapper>form>input {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 18px;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
    color: #222;
}

.contactFormWrapper>form>div>label {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    font-size: 19px;
    margin-right: 1rem;
}

.mess {
    width: 80%;
    text-align: center;
    color: #444;
    font-size: 19px;
}

.area {
    width: 300px;
    height: 150px;
   
    color: #222;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
}

.carWrapper {
    width: 100%;
    height: 250px;

}

.contactFormWrapper button {
    margin-top: 0.5rem;
}

.getBtn {
    background: transparent;
    color: #444;
    border: 2px #444 solid;
    padding: 0.5vh 0.5vw;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: 180ms all;
    border-radius: 3px;
}

.getBtn:hover {
    background-color: #737373;
    color: #fff;
}

.error {
    height: 70px;
    width: 250px;
    text-align: center;
    border-radius: 20px;
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #f00;
    color: #fff;
    position: fixed;
    top: 5rem;
    right: 5rem;
    font-weight: 500;
}

.success {
    z-index: -5;
   
    width: 100%;
    text-align: center;
 font-size: 20px;
    
    padding: 1.5rem;
    background-color: green;
    color: #fff;
    position: absolute;
    top: 50px;
   
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms all ease-in-out;
    opacity: 0;
}


  
  .success.show {
    opacity: 1;
    z-index: 999;
  }




@media only screen and (max-width: 1000px) {


    .contactFormWrapper {
    
   
        width:  40%;


    }

    
    .contactFormWrapper>h2 {
        font-size: 29px;
    }

        
        


    .contactFormWrapper> p {
        font-size: 16px;
    }
    .contactFormWrapper>form>input {
        width: 80%;
    }


    .area {
        width: 80%;
        

    }
}

@media only screen and (max-width: 800px) {

    .contactFormWrapper {
        width:  60%;
      
       }

   .contactFormWrapper>h2 {
    font-size: 27px;
}




}



@media only screen and (max-width: 600px) {

    .contactFormWrapper {
        width:  70%;
     
      
       }

       .getBtn {
       
        padding: 0.4vh 0.4vw;
       
        font-size: 14px;
       
    }

    .contactFormWrapper button {
        margin-top: 0.2rem;
    }

   
    

       .contactFormWrapper >  p {
        font-size:13px
       }

       .contactFormWrapper>h2 {
        font-size: 24px;
        margin-bottom: 0.2rem;
    }


 
      

    .area {
        width: 90%;
       height: 120px;
        font-size: 13px;
        
    }

    
    .contactFormWrapper>form>input {
        width: 90%;
        font-size: 15px;
    }


}


@media screen and (min-width: 1022px) and (max-height: 822px) {

    .contactForm {


        justify-content: center;
        padding-top: 9vh;

        height: 100vh;


    }


}



@media only screen and (max-width: 300px) {


    .contactForm {
        padding-top: 10vh;
    }





}