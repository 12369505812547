@media only screen and (max-width: 1402px) {

    .container {
        height: 470px !important;
    }


    .container  > img {
        width: 100% !important;

        height: 470px !important;
     
        
    }


    






}


@media only screen and (max-width: 868px) {

    .container {
        height: 380px !important;
    }


    .container  > img {
        width: 100% !important;

        height: 380px !important;
     
        
    }

}


@media only screen and (max-width: 758px) {

    .container {
        height: 330px !important;
    }


    .container  > img {
        width: 100% !important;

        height: 330px !important;
     
        
    }

}


@media only screen and (max-width: 498px) {


    .container {
        height: 250px !important;
    }


    .container  > img {
      

        height: 300px !important;
     
        
    }


    






}


@media only screen and (max-width: 328px) {


    .container {
        height: 220px !important;
    }


    .container  > img {
      

        height: 300px !important;
     
        
    }


    






}

