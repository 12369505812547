.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
  
    background-color: #FAF9F6;

    
   
}


.wrapper {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:1.5rem;

}

    




.wrapper > h2 {
    font-size: 46px;
    width: 60%;
    text-align: center;
   
 
}

.wrapper > p {
    


    font-size: 18px;
    color: darkgrey;
    text-align: center;
    width: 50%;
 

}





.buttonK {

    background-color: #333;
   font-size: 16px;
   color: #fff;
   padding: 6px 10px;
text-transform: uppercase;
   width: auto;
   font-weight: 600;
   border: 2px white solid;
   cursor: pointer;
  transition: 180ms all ease-in-out;
  border-radius: 3px;
 
}

.buttonK:hover {
    background-color: #737373;
    
}



.img {
    width: 70px;
    height: 70px;
    margin: 0 1.2vw;
    margin-top: 1vh;
    

}


@media only screen and (max-width: 1187px) {

    .container {
        padding-top: 4vh;

    }
    .wrapper> h2 {
        font-size: 30px;
        padding-top: 2vh;
       
        text-align: center;
     
    }

    .wrapper > p {
            font-size: 17px;
            

    }

  

      .img {
        width: 50px;
        height: 52px;
    
    }

    .buttonK {
        font-size: 13px;

    }


}

@media only screen and (max-width: 500px) {


    .wrapper> h2 {
        font-size: 25px;
      
        text-align: center;
     
    }

    .wrapper > p {
        font-size: 15px;

        width: 80%;
        

}




}