.privacyNotice {

    padding-top: 15vh;
    padding-bottom: 6vh;
    background-color: #fff;
    min-height:100vh;
    color: #555;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
  }

  .privacyNotice > h2 {

    font-size: 45px;
    color: #222;
    margin: 2vh 0;
    text-align: center;

  }

  .privacyNotice > h3 {

    font-size: 35px;
    margin: 4vh 0;
    color: #222;
    text-align: center;

  }

  .privacyNotice > p {
    text-align: center;
    font-size: 23px;
    width: 85%;
  }

  .privacyNotice > ul {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    width: 85%;
  }

  .privacyNotice > ul > li {
    width: 20%;
    color:#222 !important;
    font-size: 25px;
    font-weight: 500;
  }

 



  .privacyNotice > ul > li > p {
    padding: 1vh 0;
    font-size: 23px;
    color: #555 ;

  }

  .privacyNotice > ul > li > p  > a{
    display: flex;
    

    flex-wrap: wrap;
  }



  @media only screen and (max-width: 1068px) {


    .privacyNotice > ul {


        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .privacyNotice > ul > li {

        width: 80%;
        align-self: center;


    }











  }



  @media only screen and (max-width: 368px) {


    
  .privacyNotice > ul > li > p {
  
    font-size: 18px;
    
  }

  .privacyNotice > p {
   
    font-size: 18px;
    
  }

  .privacyNotice >  p  > a{
    font-size: 13px;


  }

  .privacyNotice > h2 {

width: 90%;

  }

  .privacyNotice > h3 {
    width: 90%;

  

  }




  }
