.listPropertyWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2d2c2c !important;
  width: 100%;
  height: 100%;

  border-radius: 20px;
  padding: 0.5vh 0.2vw;

}

.listPropertyWrapper>h2 {
  text-align: center;
  margin: 0.2rem 0;
  margin-bottom: 3vh;
  font-size: 38px;
  color: #fff;
}

.listPropertyWrapper>form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7rem;
  width: 90%;
}


.listPropertyWrapper>form>div>label {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #fff;
  font-size: 19px;
  margin-right: 1rem;
}


form>.inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  justify-content: space-between;
}


.inputs>label {
  font-size: 18px;
  width: 100%;
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: space-between
}


.inputs>label>input {
  font-size: 17px;

}


.removeIcon {
  width: 30px;
  height: 30px;
  margin-left: 12px;
  color: #fff;
  margin-top: 12px;
  font-weight: bold;
  align-self: flex-start;
  position: absolute;
  cursor: pointer;
  z-index: 99;
}


.removeIcon:hover {
  background-color: #777;
}


.keywords {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  
}


.keywords > .keyword {
  width: auto;
  height: auto;
  max-height: 300px;

}



.listPropertyWrapper>form>select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #222;
  outline: none;
  padding: 0.5rem 0.25rem;
  width: 80%;
}





.scripTitle {
  font-weight: 600;
  font-size: 22px;
  margin: 2vh 0;
  margin-top: 4vh;
  text-transform: uppercase;
  text-align: center;
  color: #fff; 
}

.scripTitle > span {
  font-weight: 400;
  color: green;
  font-size: 20px;
}

.changeRep {
  font-size: 17px;
  text-align: center;

}

.options {
  width: 80%;
  font-size: 18px;
}

.scrip {

  width: 100%;

  font-size: 18px;
  height: 400px;
}

.types {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
  font-size: 18px;
  color: #fff;
  gap: 1rem;
}

.types2 {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  font-size: 18px;
  justify-content: space-evenly;
}

.upload {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  justify-content: center;
}



.type>label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2vh;
}




.radio {
  padding-top: 2vh;
  display: flex;
  font-size: 17px;
  gap: 2rem;
}

.radio>label>input {
  margin-right: 0.5vw;
}


.garden {
  font-size: 18px;
  font-weight: 600;
}


.keywordDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  margin-bottom: 1vh;
}


.speshInput {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-size: 14px;
  border: 0;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.09);
  width: 180px;
}


.btnUpload2 {
  color: white;
  display: flex;
  cursor: pointer;
  font-weight: 400;
  padding: 0.5vh;
  border: 2px #fff solid;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;


}

.btnUpload2:hover {
  color: green;
  border: 2px green solid;

}



.btnUpload {
  color: white;
  display: flex;
  cursor: pointer;
  background: goldenrod;
  font-weight: 400;
  padding: 0.5vh;
  border: 2px #fff solid;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;


}

.btnUpload:hover {
  color: green;
  border: 2px green solid;

}


.photoDel {
  font-weight: 600;
  color: #222;
  background: #9f9f9f;
  cursor:pointer;
}

.photoDel:hover {
 
  background: #fff;
  
}




.keywords2 {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  margin-bottom: 3vh;
}


.keywords2 > .keyword {
  display: flex;
  flex-direction: column;
   align-items: center; 
   justify-content: space-between; 
   width: auto; 
   height: auto;
   font-size: 16px; 
   padding: 5px;
   border: 2px #fff solid; 

}

.keywords2 > .keyword  > p {
  text-align: center;
}

.formButton2 {

  color: white;
  width: 50%;
  margin: 7vh 0;
  background: transparent;
  border: 2px white solid;
  font-weight: bold;
  font-size: 25px;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
}


.formButton2:hover {
  color: goldenrod;
  border: 2px goldenrod solid !important;
 

}

.photoUnit {
  display: flex;
  flex-direction: column;
}

.map {
  margin-top: 2vh;
  width: 92%;
  height: 100%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);

  transition: opacity 0.5s ease-in;
}


@media only screen and (max-width: 1105px) {


  .topBar {

    gap: 3rem;

  }






  .section>h1 {
    color: #fff;
    font-size: 40px;
    padding-top: 3vh;
    text-align: center;


  }

  .section>img {
    height: 100px;
    width: auto;
  }


  .profileImg {
    margin-top: 2vh;
    border-radius: 50%;
    height: 120px;
    overflow: hidden;
    width: 120px;
    cursor: pointer;

  }

  .profileImg>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }





}


@media only screen and (max-width: 900px) {

  .listPropertyForm {



    width: 80%;
  }


  .section2>p {
    font-size: 18px;


  }


 






  .properties {

    flex-direction: column;


  }

  .property {

    width: 90%;

  }

  .property>.details>.propName {
    font-size: 20px;


  }


  .section>h1 {
    display: none;


  }

  .topBar {

    gap: 2rem;

  }





}


@media only screen and (max-width: 600px) {


  

  .inputs>label {
    flex-direction: column;
   


  }

  .listPropertyWrapper>form>select {
    font-size: 17px;
  }

  .formButton2 {

    width: 60%;
    padding: 0 1vw;

    font-size: 20px;


  }

  .garden > input {
    margin-left: 2vw;
  }


  .wrapper > h2 {
    font-size: 30px;
  }

  .property>.imgContainer {
    height: 110px;
  }

  .scrip {
    font-size: 16px;
  }

  .property>.details>.propName {
    font-size: 18px;


  }

  
.inputs>label {
  font-size: 15px;
 
}


.inputs>label>input {
  font-size: 16px;
  width: 90%;

}


  .listPropertyWrapper>h2 {
    font-size: 30px;
  }

  .listPropertyForm {



    width: 90%;
  
  }

  .keywordDiv {
    gap: 2rem;
    flex-direction: column;
    
  }

  .keywords2 {
    flex-direction: column;
  }

  .keyword {
    width: auto !important;
  }



 

  .btnUpload2 {
    font-size: 18px;
    
  }

  .types {
    gap: 2rem;
  }

  .formButton2 {

    width: 70%;
    padding: 0 1vw;


  }

  .radio {
    flex-wrap: wrap;

    gap: 1rem;
  
    font-size: 16px;
  }


  .section>img {
    height: 60px;
    width: auto;
  }

  .profileImg {

    height: 80px;

    width: 80px;

  }

  

  .inputs {
    width: 80% !important;
  }

  .inputs>label {
    text-align: center;
    
   
  }





}


@media only screen and (max-width: 300px) {

 

  .inputs>label>input {
   
    width: 100%;
  
  }
  .listPropertyWrapper > form {
    width: 85%;
  }

  .keywordDiv {
    width: 90%;
    flex-direction: column;
  }

  .keyword {
    width: 80% !important;
  }

  .btnUpload2 {
    font-size: 16px;
    width: 40%;
    
  }

  




  .listPropertyWrapper>h2 {
    font-size: 25px;
  }

  .wrapper > h2 {
    font-size: 25px;
  }

  .topBar {
    margin: 0 1vw;
    gap: 1rem;
  }


  .section2 >p {
    font-size: 16px;
  }

  .selectorDiv {
    width: 100%;
  }




}