.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #FAF9F6;
}


.wrapper {
    width: 90%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 6vh 0;
  
}


  



  .recs.appear {
  opacity: 1;
  transform: translateY(0);
}



.recs {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
   
    width: 30%;
    border-bottom: 0.5px black solid;
    transition: 175ms all ease-in-out;
    cursor: pointer;
    padding-bottom: 3vh;
    opacity: 0;
    transform: translateY(20%);
    transition: all 0.2s ease-in;
}

.recs.appear {
    opacity: 1;
    transform: translateY(0);
  }

.recs:hover {
    background-color: #222;
    color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    opacity: 0.9;
}



.bottom {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 3vh;
}



.bottom > p {
    font-size: 14px;
    text-align: center;
    color: grey;
   
  
    width: 80%;
    text-overflow: ellipsis;
}

.bottom > h5 {
    font-size: 21px;
    text-align: center;
   
    margin-bottom: 1vh;
}

.top {
    display: flex;
    align-items: center;
   
    width: 100%;
    height: auto;
    
}

.top > img {
    object-fit:cover;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1187px) {


}
@media only screen and (max-width: 668px) {
    .wrapper {
        flex-direction: column;

            width: 90%;
    }


    .recs {

        width:100%;
    }

  



}


@media screen and (min-width: 1022px) and (max-height: 822px) {

    .wrapper{
        width: 100%;
    }

 

   
    
    
}


@media screen and (max-width: 800px) and (max-height: 400px) { 

    .wrapper {
        width: 95%;
    }


 
  
  
  
   }