.container {
  width: 100%;
  background-color: #222;
  height: 90vh;
}

.absolute {

  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: left;
  align-items: left;
  gap: 1rem;
  width: 100%;
  top: 120px;



}

.abImg {
  position: absolute;
  height: 90vh;
  opacity: 0.4;
  width: 100%;
  top: 0;
}

.abImg>img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}


.left {
  margin-left: 18vw;
}

.left>h2 {
  font-size: 50px;
  width: 60%;
  color: white;
  font-weight: bold;
  text-align: left;
  
}

.left>p {
  width: 60%;
  color: white;
  text-align: left;

  font-size: 20px;

}

.valButton {
  margin: 25px 0;
  color: white;
  width: 22%;
  background: transparent;
  border: 2px white solid;
  font-weight: 500;
  padding: 0.5vh 0.2vw;
  font-size: 15px;
  cursor: pointer;
  transition: 180ms all;
  border-radius: 3px;
}

.valButton:hover {
  background-color: #fff;
  color: #444;
}

.stars {
  margin-top: 0.7vh;


}

.star {
  color: goldenrod;

}

@media only screen and (max-width: 1187px) {



  .container {

    height: 450px;
  }


  .abImg {

    height: 450px;


  }

  .absolute {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 120px;


  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-top: 2vh;
  }

  .left>p {
    margin-top: 10px;
    font-size: 13px;
    text-align: center;
    display: none;
  }

  .left>h2 {
    text-align: center;
    font-size: 36px;
    width: 50%;
  }


  .right {
    display: none;
  }


  .valButton {
    margin-top: 10px;
    font-size: 10px;
    padding: 0.2vh 0.1vw;
    width: 22%;
  }

}

@media only screen and (max-width: 1067px) {

  .absolute {


    top: 120px;



  }


}

@media only screen and (max-width: 738px) {




  .container {

    height: 310px;
  }

  .abImg {
    height: 310px;

  }

  .absolute {
  
  
    top:100px;
     
    
      
    }

  .stars {
    margin-left: 0;
    display: none;

  }

  .left>h2 {
    text-align: center;
    font-size: 24px;
    width: 70%;

  }

}


@media only screen and (max-width: 438px) {

 

}




@media only screen and (min-width: 1700px) {

  .left {
    margin-left: 23vw;
  }

  .absolute {
  
  
    top:140px;
     
    
      
    }


}