* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
 
}


.modal::-webkit-scrollbar {
  display: none;
  position: relative;
}


.surrounder {
  position: relative;
  width: 100%;
  overflow: hidden;
}


.valButton {
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: white;
  width: 20%;
  background: transparent;
  border: 2px white solid;
  font-weight: 500;
  padding: 0.5vh 0;
  font-size: 15px;
  cursor: pointer;
  transition: 180ms all;
  border-radius: 3px;
}

.valButton:hover {
  background-color: #fff;
  color: #444;

}


@media only screen and (max-width: 1187px) {

  .specialDissapearance {
    display: none;
  }


}


@media only screen and (max-width: 800px) {

  .modal {
    width: 80% !important;
  }


  .valButton {
    margin-top: 15px;
    color: white;
    width: 25%;
    background: transparent;
    border: 2px white solid;
    font-weight: 500;
    padding: 0 0.1vw;
    font-size: 10px;
    cursor: pointer;
    transition: 180ms all;
    border-radius: 3px;
  }



}









