.container {
    width: 100%;
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh 0;

}




.wrapper {
    display: flex;
    justify-content: space-around;

    align-items: center;
    margin-top: 5vh;
    width: 70%;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    

}


.wrapper.appear {
    opacity: 1;

}

.square {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 230px;
    height: 170px;
    border: 2px solid black;
    background-color: #fff;
    flex-wrap: wrap;
  
}

.square > h5 {
    text-align: center;
   
    margin-top: 1.2vh;
}

.square > p {
    text-align: center;
    font-size: 14px;
    
    max-width: 80%;
    
}

.stars {
    display: flex;
    justify-content: center;
   
}

.star {
    color: goldenrod;
    height: 30px;
}

.btn {
    margin-top: 4vh;
    margin-bottom: 4vh;
    
   
    padding: 5px;
    background-color: transparent;
    color: #444;
    border: 2px solid #444;
    max-width: 100px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: 180ms all;
    border-radius: 3px;
}

.btn:hover {
    background-color: #737373;
    color: #fff;
}

@media only screen and (max-width: 988px) {
    .wrapper {
        flex-direction: column;
        gap: 1.5rem;
        
    }
    .container {
       
            padding: 3vh 0;
    }

    .square {
        width: 90%;
    }

    .square > p {

        font-size: 12px;

    }
   
}