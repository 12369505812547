.containerOne {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FAF9F6;
    border-bottom: 0;
    padding: 2vh 0;
}

.wrapper {
    margin-top: 3vh;
    margin-bottom: 3vh;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    transition: all 0.5s ease-in-out;
    
}

.helloImage {
    width: 85%;

    height: 550px;
}

.helloImage > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


  



 


.wrapper > h2 {
    text-align: center;
    font-size: 35px;
    color: #222;
 
    
}

.wrapper > p{
    text-align: center;
    width: 70%;
    font-size: 19px;
    color: gray;
}


.containerTwo {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FAF9F6;
}

.wrapperTwo {
    
    margin-bottom: 10vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    transition: all 0.5s ease-in-out;
}

.wrapperTwo > .cont {
    text-align: center;
    font-size: 21px;
    margin-bottom: 3vh;
    width: 80%;
   
    

}

.wrapperTwo > .address {
    text-align: center;
    font-size: 21px;
    width: 80%;
    
}

.wrapperTwo > img {
    width: 70%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25); 
}


.cont > span {
    font-weight: 500;
    color:  #f8af8f;

}


.address > span {

    font-weight: 500;
    color:  #f8af8f;


}
@media only screen and (max-width: 668px) {

    .wrapperTwo > img {
        width: 80%;
        margin-top: 2vh;
       
        height: 400px;
    }

    .helloImage {
        width: 85%;
    
        height: 350px;
    }


    .wrapper > h2 {
        text-align: center;
        font-size: 27px;
     
    }



    .wrapperTwo {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100% !important;

    }


   

    .wrapperTwo > .cont, .wrapperTwo > .address {

            text-align: center;
            width: 80%;
            font-size: 15px;

    }




}


@media screen and (min-width: 1022px) and (max-height: 822px) {

    .wrapper > h2 {
        font-size: 28px;
    }


    .cont {
        font-size: 18px !important;
        
    
    }
    
    
    .address  {

        font-size: 18px !important;
    
       
    
    
    }



}



@media screen and (max-width: 800px) and (max-height: 400px) { 

    .wrapper > h2 {
        font-size: 28px;
    }


    .cont {
        font-size: 18px !important;
        
    
    }
    
    
    .address  {

        font-size: 18px !important;
    
       
    
    
    }
    
  
  
  
   }