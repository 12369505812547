.navbar {
  background-color: transparent;
  padding-top: 5.5vh;
  padding-bottom: 5.5vh;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  font-size: 18px;
  position: fixed;
  top: 0;
  font-weight: 400;
  z-index: 99999999999999999;
  transition: 135ms all ease-in-out;
}

.navContainer {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  height: 85px;
  width: 100%;
}

.mainContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent
}





.navLogo {

  max-width: 65px;
  flex: 1;
  height: 65px;
  cursor: pointer;
  
  margin: 0.4vh 1.6vw;
}

.navLogo>img {
  width: 100%;
  height: 100%;
}

.searchIcon {
  margin-top: 2.1vh;
  margin-right: 22px;
  cursor: pointer;
}

.navMenu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  gap: 3.5rem;

}

i {
  color: #f8af8f;
  font-weight: bold;


}

.navLinks {
  color: #fff;
  text-decoration: none;
  padding: 1rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}


.navItem {
  line-height: 40px;

  cursor: pointer;


}



.navItem:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.navItem:hover:after {
  width: 100%;
  background: #ffdd40;
}

.navItem.active {
  color: #ffdd40;
  border: 1px solid #ffdd40;
  padding-bottom: 1vh;
}

.navIcon {
  display: none;
  width: 50px;
  height: 45px;
  cursor: pointer;


}

.button {
  flex: 1;
  font-weight: 600;
  margin: 0 19px;
  padding: 1vh 1vw;
  background-color: #333;
  color: #fff;
  margin-bottom: 0.55vh;

  border: 1px solid transparent;
  max-width: 185px;
  font-size: 17px;
  cursor: pointer;
  transition: 180ms all;
  border-radius: 3px;
}


.button:hover {
  background-color: #737373;
}





.menu>ul li {
  flex: 1;
  position: relative;
  margin: 0 3px;
}

.navContainer {
  position: relative;
}




.navContainer>ul li ul,
.navDrop2 {
  visibility: hidden;
  opacity: 0;
  margin: 1vh 0;
  padding: 0.5vh 0.5vw;
  position: absolute;
  background-color: #333 !important;

border-radius: 3px;
  font-size: 15px;

  color: #fff !important;
  transform: translateX(0.5px);


  transition: 0.2s;
  transition-delay: 200ms;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  text-transform: uppercase;
}

.navDrop2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 10px;
}



.navContainer>ul li ul>li:hover {
  color:#999999;
}



.specialBox {
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: #fff !important;
  background-color: #1877F2;
  padding: 2vh 2vw;
  top: 355px;
  left: 69vw;
  border-radius: 3px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
}

.specialBox>.span {
  color: #fff !important;
  cursor: pointer;

}

.specialBox>.span:hover {
  color: #444 !important;


}

.specialBox>.logoutBtn {
  border-radius: 5px;
  cursor: pointer;


}

.title {
  font-size: 14px;
  color: lightgreen !important;
}

.navContainer>ul li:hover>ul,
.navItem:hover>.navDrop2 {
  opacity: 1;
  visibility: visible;
  transition-delay: 0ms;

}

.navDrop {
  background-color: #fff !important;
  color: grey !important;
  list-style-type: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
}

































@media screen and (max-width: 1310px) {


  .specialBox {
    display: none;
  }

  .navbar {

    height: 80px;
  }

  .button {
    font-size: 12.5px;
  }


  #areaG {
    display: none;
  }




}

@media screen and (max-width: 1187px) {

  .navMenu {

    gap: 2rem;
    font-size: 15px !important;
    top: 84px !important;
    padding: 1vh 0;

  }



  .navContainer {
    width: 100%;
  }

  .searchIcon {
    display: none;
  }

  .navDrop2 {
    display: none;
  }

  .searchItem {
    display: none;
  }



}





@media screen and (max-width: 1187px) {

  .button {
    display: none;
  }








  .navMenu {

    margin: 0;
  }

  .navItem {
    padding: 1vh 1vw;
    line-height: 20px;

  }

  .navContainer {
    justify-content: center;
    gap: 0;


  }

  .navbar {
    justify-content: space-between;
    font-size: 17px;


  }




  .navContainer>ul li ul {

    width: 100%;
    left: 0;


  }







  .navMenu {
    padding-bottom: 2vh;
    display: none;
    flex-direction: column;
    background: transparent;
    color: transparent;
    font-size: 15px;
    position: absolute;
    top: 78px;
    left: 0;
    opacity: 0.7;
    transition: all 3s ease;
  }









  .active1 {

    width: 100%;
    display: flex;
    background: #255e64;
    color: white;
    left: 0px;

    flex-direction: column;
    align-items: center;

    opacity: 0.97;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .active1>li {
    opacity: 1 !important;
    font-weight: bold;
  }

  .navItem.active {
    color: #ffdd40;
    border: none;
    height: 20px;
    text-align: center;

  }


  .navLinks {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .navDrop {
    width: 100%;
    list-style-type: none;
    transform: translateX(-50%);
    line-height: 40px;
    z-index: 999;
  }

  .navIcon {
    display: flex;
    flex-direction: column;

    align-items: center;
    cursor: pointer;
    color: #ffdd40;
    justify-content: center;


  }

  .navItem {
    color: #fff !important;
  }
}


@media screen and (max-width: 510px) {


  .navbar {
    height: 80px;
  }


  .navLogo {
    height: 60px;
    max-width: 60px !important;


  }


  .navMenu {

    top: 78px !important;
    padding: 1vh 0;
    padding-bottom: 2vh;

  }


  .navItem {
    height: 24px;


  }




}



@media screen and (max-height: 510px) {


  .navbar {
    height: 80px;
  }




  .navMenu {

    top: 55px !important;
    padding: 1vh 0;
    padding-bottom: 2vh;


  }


  .navItem {
    height: 24px;


  }




}


@media screen and (max-width: 310px) {

  .navMenu {

    top: 74px !important;
    padding: 1vh 0;



  }



}




@media screen and (max-width: 800px) and (max-height: 400px) { 

  .navLogo {
    height: 60px !important;
    max-width: 60px !important;


  }



  .navbar {
    height: 80px;
  }



 }


 @media screen and  (max-height: 600px) { 

  .navLogo {
    height: 60px !important;
    max-width: 60px !important;


  }



  .navbar {
    height: 80px;
  }



 }








