.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    
}

.wrapper {
    margin-top: 5vh;
    margin-bottom: 5vh;
   align-items: center;
   justify-content: center;

    height: 100%;

    width: 80%;

    gap: 2rem;
    display: flex;
  
  
    
    

}

.column {
    display: flex;
    flex-direction: column;
align-items: center;
    width: 40%;
    max-height: 60%;
    min-height: 60%;
    gap: 3rem;
}

.column > p {
    text-align: center;
    width: 80%;
    height: 60%;
    font-size: 16px;
    margin-bottom: 4.5vh;
    
    color: grey;
}

.column > h3 {
    text-align: center;
    
    color: darkgray;
    font-size: 22px;
    width: 70%;
}



 
 .whyButton {
   
    background: transparent;
    color: #444;
    border: 2px #444 solid;
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: 180ms all;
    border-radius: 3px;
    min-height: 40px;
    max-height: 40px;
    
}

.whyButton:hover {
    background-color: #737373;
    color: #fff;
}


.wrapper {
    opacity: 0;
    transform: translateY(15%);
    transition: all 0.4s ease-in;
  }
  
  



  .wrapper.appear
 {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (max-width: 1200px) {

    .column > p {

margin-bottom: 7.5vh;
    }


}

@media only screen and (max-width: 1050px) {
   

    .whyButton {
      font-size: 11px;
      margin-bottom: 0.1vh;
      min-height: 40px;
    max-height: 40px;
    }

    .column > h3 {

        align-items: center;

    }

    .wrapper {
        width: 70%;
        
         
    }

    .column > p {
        display: none;
       
    }



    .column {
     
        justify-content: center;

        max-width: 25%;
        min-width: 25%;
        
        

  }
}



  @media only screen and (max-width: 800px) {

    .whyButton {
        font-size: 9px;
        text-align: center;
       

        padding: 2px;
      }

      .wrapper {
        width: 80%;
        padding: 0 3vw;
       
        gap: 2rem;
    }

    .column > h3 {
        width: 80%;
        font-size: 14px;
        text-align: center;
       
    }

   
  }