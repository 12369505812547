.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 3vh ;
}

.wrapper {
   
    margin-bottom: 2vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    justify-content: space-between;
    
}

.sideWrap {
    display: flex;
    flex-direction: column;
    
    
    flex: 1;
    align-items: center;
}

.wrapper > img {
    width: 100%;
    height: 450px;
    flex: 1;

}

.sideWrap > h2 {
    text-align: center;
    font-size: 46px;
    margin-bottom: 3vh;
   
}

.sideWrap > p{
    text-align: center;
    font-weight: 600;
    
    
    font-size: 20px;
    color: #222;
 
}

.p1 {
    width: 70%;
    font-weight: bold;
     color: #555;
}

.wrapperTwo {
   
    margin-bottom: 0.2vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.text {
    display:  flex;
    flex-direction: column;
    align-items: left;
    padding: 3px;
    padding-bottom: 5vh;
    gap: 20px;
    
}

.sideWrap > .extraText  {
    font-size: 18px;
     color: #222;
     text-align: left;
     padding-bottom: 3vh;
     font-weight: 400;
     width: 100%;

}

.sideWrap, .wrapperTwo {
    opacity: 0;
    transition: opacity 0.5s ease-in;
}


.sideWrap.appear, .wrapperTwo.appear {
  opacity: 1;
}




.text > p {
    text-align: left;
    width: 95%;
    font-size: 18px;
    color: #222;
}

@media only screen and (max-width: 1187px) {

    .wrapper {

        flex-direction: column;
        align-items: left;


    }


    .sideWrap {

            align-items: left;

    }


    .sideWrap > .extraText  {

        font-weight: 400;


    }

    .sideWrap > h2 {
        
        font-size: 31px;
        text-align: center;
       
    }


    .sideWrap > p{

       width: 70%;
        font-size: 18px;
        text-align: left;
      
        color:#222;
       


    }

    .p1 {
        margin-top: 1vh;
        width: 100% !important;


    }

    .wrapperTwo {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

   .wrapperTwo > .text  > p{
        text-align: left;
        font-size: 17px;

    }

   .sideWrap > .extraText {
        text-align: left;
        padding-bottom: 1vh;
        font-size: 17px;
    }


}

@media only screen and (max-width: 600px) {

    .sideWrap > h2 {
        
        font-size: 25px;
      margin-bottom: 2vh;
      text-transform: uppercase;
       
    }




}


