.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FAF9F6;
}

.wrapper {
    padding: 3vh 0 !important;
    width: 80%;
    display: flex;
    height: auto;

    justify-content: center;
    flex-direction: column;
    align-items: center;

 
    
}





.row {
    display: flex;
   
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
}

.wrapper > p {
    text-align: center;
    font-size: 19px;
    width: 70%;
    color: gray;
    padding: 1vh 0;
    margin-bottom: 3vh!important;
   
}

.wrapper > h4 {
    text-align: center;
    font-size: 37px;
    width: 60%;

    margin: 1vh 0;
    padding: 0;
    
   
  
}

.wrapper >  h6{
    text-align: center;
    font-size: 26px;
    margin-top: 5vh;
    
}


.box {
  
    padding: 2vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:5rem;
}


.alignLeft {
    
    width: 100%;
    height: auto;
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh 0;


}

.alignRight {

   
   
    width: 100%;
    height: auto;
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh 0;

}

.row {
    opacity: 0;
    transform: translateX(-25%);
    transition: all 0.35s ease-in;
    height: auto;
  }


  .row:nth-child(even){
    opacity: 0;
    transform: translateX(25%);
    transition: all 0.35s ease-in;
  }
  
  

  .row.appear {

    transform: translateX(0);
    opacity: 1;
  }



.imgContainer > img{
    object-fit: cover;
    width: 100%;
    height: 100% !important;
  
    
}

.row > .imgContainer {
    width: 100%;

    
    height: auto;
    flex: 1;
}


.alignLeft > p, .alignRight > p {
    text-align: center;
    color: #222  !important;
    width: 80%;
   
    font-size: 18px !important;
}



.alignLeft > h5, .alignRight > h5 {
   
    text-align: center;
    font-size: 24px;
    margin-bottom: 2vh;
    color:#444;
}

.btnLet {
    margin: 30px 0;
  
    background-color: #333;
   font-size: 16px !important;
   color: #fff;
   padding: 5px 8px;
   font-weight: 600;
   border: 2px white solid;
   cursor: pointer;
  transition: 180ms all;
  border-radius: 3px;

}

.btnLet:hover  {
    background-color: #737373;
}


@media only screen and (max-width: 1550px) {

    .wrapper {
        margin: 1vh 0;
        padding: 1vh 0;
        
    }


    .wrapper > h4 {
       
        font-size: 25px;
        width: 85%;
        text-align: center;
        margin: 2vh 0;
    }


    .wrapper > p {

        font-size: 18px;
        margin: 0;
        width: 60%;
    }

    .btnLet{
        font-size: 11px;

    }




   
    
    

 
  }



  @media only screen and (max-width: 1000px) {

 

    .row {

        flex-direction: column;

    }

    #dis {
        flex-direction: column-reverse;
    }

   

  }

  @media only screen and (max-width: 1000px) {

    .wrapper {
        width: 85%;
    }


  .wrapper > h4 {
       
    font-size: 25px;
    width: 100%;
    text-align: center;
    margin: 2vh 0;
}


.wrapper > p {

    font-size: 17px !important;
    margin: 0;
    width: 100%;
}



  }


  
  @media only screen and (max-width: 500px) {
    .alignLeft > h5, .alignRight > h5 {
           
        font-size: 24px;
       
    }
    
    .alignLeft > p, .alignRight > p {
    
        font-size: 14px !important;
       
    
    }

    .wrapper > h4 {
       
        font-size: 22px;
        width: 100%;
        text-align: center;
        margin: 2vh 0;
    }
    
    
    .wrapper > p {
    
        font-size: 15px !important;
        margin: 0;
        width: 100%;
    }


  }


  @media screen and (min-width: 1022px) and (max-height: 822px) { 

    .alignLeft > p, .alignRight > p {
      
        font-size: 16px !important;
    }
    
    
    
    .alignLeft > h5, .alignRight > h5 {
        padding-top: 1vh;
       
        font-size: 22px;
      
    }






   }