.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 3vh 0;

}

.wrapper {
    width: 85%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper > h3{
    margin: 4vh 0;

    font-size: 35px;
}

.wrapper > p {
    color: gray;
    font-size: 16px;
    margin-bottom: 3vh;
}

.rect {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 3vh;
    width: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    padding: 5px;

}
.top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    padding: 5px;
   
}


    .stars {
        margin: 5px;
    
}
.bottom{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 3px;
}

.bottom > p {
    margin: 2px;
    text-align: left;

}



.stars {
   
   
  }
  
  .star {
    color: goldenrod;
   
  }


  .controls>button {
    outline: none;
    border: none;
    padding: 0.25rem 0.75rem;
    font-size: 16px;
    background-color: #f00;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.btnDels {
    display: flex;
    justify-content: space-around;
    margin-top: 2vh;
}

.btnDel {
    border: 2px #fff solid;
    background: transparent;
    color: #fff;
    font-weight: bold;
    padding: 0.5vh 0.5vw;
    cursor: pointer;
    transition: 180ms all;
    border-radius: 3px;
}


.btnDel:hover {
    background-color: #555;

}


@media only screen and (max-width: 768px) {

    .wrapper > p {
        text-align: center;
    }



    .wrapper > h3{
        font-size: 28px;
        text-align: center;


    }



}