.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FAF9F6;
}

.wrapper {
    width: 100%;
    display: flex;

    padding: 3vh 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.wrapper > h4 {
    text-align: center;
    font-size: 30px;
    margin-top: 3vh;
    margin-bottom: 3vh;
    
}

.box {
    width: 60%;
    height: 100%;
    border: 3px black solid;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    margin: 5vh 0;
    background: #fff;
}



.wrapper > p {
    text-align: center;
    width: 40%;
}

.speshButton {

    background: transparent;
    color: #444;
    border: 2px #444 solid;
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: 180ms all;
    border-radius: 3px;
    margin: 2vh 0;
    

}


.speshButton:hover {
    background-color: #737373;
    color: #fff;
 }

 @media only screen and (max-width: 1268px) {

    .wrapper > h4 {
        font-size: 24px;
        width: 80%;

    }


    .wrapper > p {

            width: 60%;

    }
    
    .box {
        width:75%;


    }


 }