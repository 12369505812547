


  .window {
    width: 100%;
    height: 90vh;
    overflow:hidden;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: darken;

   position: relative;

  
  }

  .absolute {
    
    position: absolute;
    z-index: 9;
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    top: 120px;

 
    transition: opacity 0.5s ease-in;

  
  
  }



  .left {
    margin-left: 18vw;
  }

  .left> h2 {
    font-size: 57px;
    line-height: 120%;
    width: 60%;
    color: white;
    font-weight: 700;
    text-align: left;
    margin-bottom: 2vh;
  }

  .left > p {
    width: 40%;
    color: white;
    text-align: left;
    
    font-size: 18px;

}

 .valButton {
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: white;
  width: 20%;
  background: transparent;
  border: 2px white solid;
  font-weight: 500;
  padding: 0.5vh 0;
  font-size: 15px;
  cursor: pointer;
  transition: 180ms all;
  border-radius: 3px;
}

.valButton:hover {
  background-color: #fff;
  color: #444;

}

.stars {
  margin-top: 0.7vh;
  
 
}

.star {
  color: goldenrod;
 
}
@media only screen and (max-width: 1187px) {
    
    .absolute {
    
      flex-direction: column;
      align-items: center;
        width: 100%;
        left: 0;
        top: 120px;
        
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       width: 100%;
       margin-left: 0;

       margin-top: 2vh;
      }

      .left> p {
        margin-top: 10px;
        font-size: 17px;
        text-align: center;
        display: none;
      }

 
      .left > h2 {
        text-align: center;
        font-size: 39px;
        width: 50%;
      }

      .stars {
          margin-left: 0;

      }
      .right {
        display: none;
      }

      .window {
        width: 100%;
        height: 450px;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.6);
        background-blend-mode: darken;
      }
    

    }




    @media only screen and (max-width: 1027px) {

      .absolute {
    
  
        top: 120px;
         
        
          
        }

        .valButton {
          
       
      
          font-size: 10px;
       
        }


    }


    @media only screen and (max-width: 738px) {

      .absolute {
        top: 100px;
      }

   


      .window {
        width: 100%;
        height: 310px;
        overflow:hidden;
        background-color: rgba(0, 0, 0, 0.6);
        background-blend-mode: darken;
        
      }
    
.left > h2 {
  margin-bottom: 0.5vh;
  font-size: 27px;
}
      
 .valButton {
  margin-top: 15px;
  color: white;
  width: 25%;
  background: transparent;
  border: 2px white solid;
  font-weight: 500;
  padding: 0 0.1vw;
  font-size: 10px;
  cursor: pointer;
  transition: 180ms all;
  border-radius: 3px;
}

.stars {
  display: none;
}


    }

    @media only screen and (max-width: 330px) {
    .left > h2 {
    
      font-size: 23px;
    }
  }


    
    @media only screen and (min-width: 1700px) {

      .absolute {
    
       
        top: 140px;
        
      }

      .left {
        margin-left: 23vw;
      }


    }



 