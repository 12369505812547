.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FAF9F6;
}

.wrapper {
    padding: 3vh 0;
    height: auto;
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
}


.box {
    display: flex;
    height: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2.3rem;
    padding: 6vh 0;
}

.row {
    display: flex;
   
    
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
}

.wrapper > p {
    text-align: center;
    font-size: 21px;
    width: 80%;
    color: #555;
    
   
}

.wrapper > h4 {
    text-align: center;
    font-size: 42px;
    width: 80%;
    margin-bottom: 3vh;
   
}

.wrapper >  h6{
    text-align: center;
    font-size: 26px;
    margin-top: 1vh;
  
    
}





.alignLeft {
  
    width: 100%;
    height: auto;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vh 0;

}

.alignRight {
   
    width: 100%;
    height: auto;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vh 0;

}

.row {
    height: auto;
    opacity: 0;
    transform: translateX(-25%);
    transition: all 0.35s ease-in;
  }


  .row:nth-child(even){
    opacity: 0;
    transform: translateX(25%);
    transition: all 0.35s ease-in;
  }
  
  

  .row.appear {

    transform: translateX(0);
    opacity: 1;
  }

.imgContainer {
    width: 100%;
    height: auto;
    flex: 1;
    
}

.imgContainer > img{

    width: 100%;
    height: 100% !important;
    object-fit: cover;
    
}



.alignLeft > p, .alignRight > p {
    text-align: center;
    color: #222  !important;
    width: 80%;
 
    font-size: 19px  !important;
}


.alignLeft > h5, .alignRight > h5 {
 
    text-align: center;
    font-size: 27px;
    color: #444;
    margin-bottom: 2vh;

}

.btnSell {
    
    background-color: #333;
   font-size: 16px;
   color: #fff;
   padding: 5px 8px;
   font-weight: 600;
   border: 2px white solid;
   cursor: pointer;
  transition: 180ms all;
  border-radius: 3px;
  margin: 2vh 0;
  margin-bottom: 3vh;
  
}

.btnSell:hover  {
    background-color: #737373;
}


@media only screen and (max-width: 1550px) {

    .wrapper {
        margin: 1vh 0;
        padding: 1vh 0;
        
    }

    
    .alignLeft, .alignRight {
        padding-top: 0.7vh
    }


    .wrapper > h4 {
       
        font-size: 27px;
        width: 85%;
        margin: 2vh 0;
    }


    .wrapper > p {

        font-size: 17px;
        margin: 0;
        width: 60%;
    }


    .alignLeft, .alignRight {

        padding-top: 0.3vh;



    }



    .alignLeft > p, .alignRight > p {
        text-align: center;
        color: #222;
        font-size: 16px;
        padding: 2vh 2vw;

    }
    

 
  }


  @media only screen and (max-width: 1000px) {

   

    .row {

        flex-direction: column;

    }

    .alignLeft > h5, .alignRight > h5 {
        padding-top: 2vh !important;

    }

    #dis {
        flex-direction: column-reverse;
    }

    .alignLeft > h5, .alignRight > h5 {
        text-align: center;
        font-size: 22px;
        color: #444;
        padding-top: 1.7vh;
        width: 70%;
    }

    .alignLeft > p, .alignRight > p {
        text-align: center;
        color: #222;
        font-size: 15px;
        padding-top: 1vh;
        padding-bottom: 3vh;

    }

  }

  
  @media only screen and (max-width: 1000px) {

    .wrapper {
        width: 85%;
    }


    .wrapper > h4 {
         
      font-size: 25px;
      width: 100%;
      text-align: center;
      margin: 2vh 0;
  }
  
  
  .wrapper > p {
  
      font-size: 17px !important;
      margin: 0;
      width: 100%;
  }


  
    }

    @media only screen and (max-width: 500px) {

        .alignLeft > h5, .alignRight > h5 {
           
            font-size: 24px;
           
        }
        
        .alignLeft > p, .alignRight > p {
        
            font-size: 14px !important;
           
        
        }

        .wrapper > h4 {
       
            font-size: 22px;
            width: 100%;
            text-align: center;
            margin: 2vh 0;
        }
        
        
        .wrapper > p {
        
            font-size: 15px !important;
            margin: 0;
            width: 100%;
        }

    }



    @media screen and (min-width: 1022px) and (max-height: 822px) { 

        .alignLeft > p, .alignRight > p {
          
            font-size: 16px !important;
        }
        
        
        
        .alignLeft > h5, .alignRight > h5 {
            padding-top: 1vh;
           
            font-size: 22px;
          
        }
    
    
    
    
    
    
       }


