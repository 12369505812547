
  * {
    box-sizing: border-box;
  }
  
  .carousel {
    position: relative;
    width: 19rem;
    height: 23rem;
    perspective: 500px;
    transform-style: preserve-3d;
    transition: opacity 180ms ease-in;
  }
  
  .card-container {
    position: absolute;
    
    top: 6.7vh;
    left: -8vw;
    width: 100%;
    height: 100%;
    transform: 
      rotateY(calc(var(--offset) * 10deg)) 
      scaleY(calc(1 + var(--abs-offset) * -3))
      translateZ(calc(var(--abs-offset) * -80rem))
      translateX(calc(var(--direction)  * -0.00000001rem));
    filter: blur(calc(var(--abs-offset) * 2rem));
    transition: all 0.2s ease-out;
  }
  
  .card {
    width: 135%;
    height: 135%;
    padding-top: 2vh;
    background-color: hsl(280deg, 40%, calc(100% - var(--abs-offset) * 50%));
   
   border-radius: 10px;
    text-align: justify;
    transition: all 0.3s ease-out;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6); 
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
   
  }
  
  .card >h2 {
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;
    padding-top: 1vh;
    padding-bottom: 2vh;
    color: #1F2937;
  }

  .app {
    opacity: 0;
    transition: opacity 0.5s ease-in;
  }
  
  .app.fade-in {
    opacity: 1;
  }

 .card> .content {
    width: 70%;
    text-align: center;
    color: #222!important;
    font-weight: 400 !important;
    padding-top: 0vh;
    font-size: 17px;
   
    overflow: hidden;
    text-overflow: ellipsis;

  }
  
  .content,
  .card h2 {
    transition: all 0.3s ease-out;
    opacity: var(--active);
  }
  
  .card > img {
    object-fit:cover;
    height: 250px !important;
    width: 250px;
    align-self: center;
    border-radius: 50%;
    margin-bottom: 2vh;
  }

  .nav {
    color: white;
    font-size: 5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 78%;
    
    z-index: 2;
    cursor: pointer;
    user-select: none;
    background: unset;
    border: unset;
  }
  
  .nav.left {
    transform: translateX(-100%) translatey(-50%);
    left: -9vw;
  }
  
  .nav.right {
    left:  14.2vw;
    transform: translateX(100%) translatey(-50%);
  }


  @media only screen and (min-width: 1900px) {

.nav.right {

  left: 4vw;

}

  }


  @media only screen and (min-width: 1800px) {

    .nav.right {
    
      left: 7vw;
    
    }
    
      }


  @media only screen and (min-width: 1700px) {

    .nav.right {
    
      left: 10vw;
    
    }
    
      }
    

