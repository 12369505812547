.stampBtn {
    margin-top: 20px;
    background-color: #333;
   font-size: 14px;
   color: #fff;
   padding: 5px 8px;
   font-weight: 600;
   border: 2px white solid;
   cursor: pointer;
  transition: 180ms all;
  border-radius: 3px;
}

.stampBtn:hover {
    background-color: #737373;
}


.radio {
    display: flex;
    flex-direction: column;
}


@media only screen and (max-width: 1268px) {

    .container > h2 {
        text-align: center;
        font-size: 25px !important;
    }

    .property {
        width: 90%;
    }



}