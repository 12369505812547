.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FAF9F6;
}

.wrapper {
    margin-top: 6vh;
    margin-bottom: 6vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
}





.wrapper > h2 {
    text-align: center;
    font-size: 40px;
    margin-bottom: 0.5vh;
}

.wrapper > p{
    text-align: center;
    width: 70%;
    font-size: 19px;
    color: gray;
}

@media only screen and (max-width: 768px) {


    .wrapper > h2 {
        
        font-size: 23px;
        margin-bottom: 2vh;
    }


    .wrapper {
        margin-top: 5vh;
        margin-bottom: 5vh;
    }


    .wrapper > p{

        font-size: 15px;
        width: 100%;



    }

}


@media screen and (min-width: 1022px) and (max-height: 822px) { 


    
    .wrapper > h2 {
        
        font-size: 30px;
        margin-bottom: 2vh;
    }

   





   }