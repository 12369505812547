.container {
    width: 100%;
    height: 600px;
    
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: darken;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: left;
}

.wrapper {
    
    margin-bottom: 7vh;
    margin-left: 5vh;
    margin-right: 7vh;
    margin-top: 4vh;
    
    width:50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in;
   
    align-items: left;
}

.wrapper.appear
{
 opacity: 1;
}


.wrapper >  h3 {
    text-align: left;
    font-size: 46px;
    color: white;
    font-weight: bold;
    margin-bottom: 5vh;
    
}

.wrapper > p {
    text-align: left;
    width: 60%;
    color:  #fff;
    margin-bottom: 7vh;
    padding-right: 1vw;
    font-weight: 400;
    font-size: 18.5px;
}


.teamBtn {
    width: 20%;
    font-weight: bold;
    color: #fff;
    background-color: transparent;
    border: 2px #fff solid;
    font-size: 16px;
    cursor: pointer;
    transition: 180ms all;
    border-radius: 3px;
}

.teamBtn:hover {
    background-color: #fff;
  color: #444;
}

.logo {
    width: 160px;
    height: 100px;
    padding-top: 1vh;
}

.title {
    display: flex;
    flex-direction: row;
}





@media only screen and (max-width: 1328px) {
    .container {
        flex-direction: column;
        align-items: center;
    }
    
    .carouselDiv {
        display: none;
    
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2vh 0;
        padding: 2vh 0;
        gap: 1rem;

    }
    

    .wrapper > h3 {
       
        text-align: center;
        margin-bottom: 1vh;
    }
    
    .wrapper > p {
      
        text-align: center;
        margin-bottom: 2vh;
    }

  


}



@media only screen and (max-width: 968px) {

   

    .wrapper > h3 {
       
        font-size: 40px;
    }
    
    .wrapper > p {
      
        font-size: 16px;
    }

    .teamBtn {
        width: 40%;
        
        font-size: 16px;
        
    }



}

@media only screen and (max-width: 668px) {

    .wrapper {
        width: 80%;

    }

    .wrapper > h3 {
       
        font-size: 30px;
        width:90%;
    }
    

    .teamBtn {

        font-size: 11px;

    }

    .wrapper > p {
      
        font-size: 15px;
        width: 100%;
       
    }
    
  



}