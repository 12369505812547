.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4vh;
    padding-bottom: 4vh;
    background-color: #FAF9F6;
   
}

.container > h2 {
    font-size: 46px;
    width: 60%;
    text-align: center;
}

.container > p {
    
    margin-top: 3vh;
    margin-bottom: 4vh;
    font-size: 21px;
    color: darkgrey;
    text-align: center;
    width: 50%;
}

 .buttonKL {
    margin-bottom: 4vh;
   background-color: #333;
   color: #fff;
   padding: 5px 8px;
   border: 2px white solid;
   cursor: pointer;
   transition: 180ms all;
   border-radius: 5px;
   font-weight: 600;
   

}
.buttonKL:hover {
    background-color:#444;
    
  
  }

.img {
    width: 90px;
    height: 60px;

}

@media only screen and (max-width: 668px) {
    .container {
        height: 100%;
    }


    .container > h2 {
        font-size: 25px;
        

    }

    .container p {

        font-size: 15px !important;
        width: 80%;

    }

    }