.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 110px;
    background: #fff;
    color: #222;
 
    
   
}

.wrapper {
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    
   
}

.searchBar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    gap: 0.75rem;
}

.search {
    width: 35%;
    font-size: 18px;
    margin: 2vh 0;
    border: #222 1px solid;
    background-color: #fff;
    font-weight: 400;
    color: #222;
    padding: 0.5px;
    
   
}

.search::placeholder {
    color: #222; /* Change to your desired color */
    font-weight: 400;
    margin-left: 0.5vw;
  }

.bottomWrapper{
 
    display: flex;
    flex-direction: column;
    padding: 0.5vh 0.5vw;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

}

.bottomWrapper > h2 {
    font-size: 30px;
}



@keyframes oscillate {
    0% {
      color: #ddd;
    }
    50% {
      color: #999;
    }
    100% {
      color: #ddd;
    }
  }
  
  .loader {
    font-size: 30px;
    animation: oscillate 1s infinite;
   
    text-align: center;
    width: 100%;

    margin: 2vh 0;
  }




.wrapper>.options {
    
    width: 90%;
    height: 50px;
    padding: 0.75rem 0.5rem;
    margin-top: 0.5vh;
    margin-bottom: 3vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* copy & paste */
    background-color: #333;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    border-radius: 30px;
    
   
    border: 1px solid rgba(255, 255, 255, 0.18);
}




.options>label {
display: flex;
gap: 0.5vw;
font-size: 18px;
font-weight: 600;
color: #fff;

}


.options>label>select  {
    background: #fff;
    font-weight: 400;
    color: #222;
    font-size: 17px;
    
}








/* individual property css */
.properties{
    margin-top: 1rem;
    
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
  align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    
}

.property {
    height: 100%;
    background-color: #faf9f6;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    width: 345px;
    border: 1px grey solid;
    color: black;
}

.property:hover {

    background-color: #222;
    cursor: pointer;
    
    color: white;
     
  

}

.property>.imgContainer {
    display: inline-block;
    height: 225px;
    width: 100%;
    overflow: hidden;
    
}

.property>.imgContainer>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 150ms all;
}

.property>.imgContainer>img:hover {
    scale: 1.085;
} 

.owner {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.4vw;
    margin-bottom: 1vh;
}



.property>.details {
    display: flex;
   align-items: center;
    justify-content: space-between;

    padding: 0.5vh 0.5rem;
   height:auto;
    width: 100%;
   
}




.property>.details>.propName {
    
    
    font-size: 20px;
    font-weight: 400;
    

    
}






.desc {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    font-size: 14px;
    color: grey;
}

.noProperty{
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
    font-size: 36px;
    color: #222;
}

/* RESPONSIVE */@media only screen and (max-width: 1478px) {


    .options>label>select  {
       
        font-size: 15px;
        
    }

    .bottomWrapper > h2{

        text-align: center;
        font-size: 18px;

    }

    .wrapper {
        flex-direction: column;
        border:0;
        background: #fff;
        box-shadow: none; 
        padding: 0.2vh 0.2vw;
        

    }

    .search {
        width: 60%;
    }



   .wrapper > .options {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 60%;

        gap: 1vh;
       
       
        

    }



    .options>label {
        width: 95%;
        
        height: 75%;
        display: flex;
        justify-content: space-between;
    }

    .options>label>select {
        width: 50%;
    }

    .options> .gardenLabel > input{
        width: 25px;
        height: 25px;
    }

    
 

  
    
    
}


@media only screen and (max-width: 1310px) {

.container {
    padding-top: 105px;


}



}



@media only screen and (max-width: 792px) {
    .wrapper>.options{
        width: 80%;
        padding: 0.75rem 1rem;
        margin: 0 auto;
        font-size: 11px;
    }



  

    .properties{
       
       align-items: center;
       
       
       
    }

    .property {
        width: 40%;
        height: 100%;
        
    }


    .property>.details> p {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
    }

    .desc {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 250px;
        font-size: 11px;
        color: grey;
    }
    
    .property>.imgContainer {

        max-height: 200px;
        width: 100%;
        min-height: 200px;
    }

    .property>.details>.propName {
        
        font-size: 13px;    


    }
    .price {
        display: flex;
        font-size: 11px;
    }

}


@media only screen and (max-width: 492px) {

    .options {
        margin: 2vh 0 !important;
        width: 80% !important;
    }


    .options>label {
        width: 90%;
        
        height: 75%;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        flex-direction: column;
    }

    .options>label>select {
        width: 100%;
    }

.options > .gardenLabel  {
    flex-direction: row;
    margin: 1vh 0;
}


    .search {
        width: 80%;
        font-size: 16px;

    }
  


    .property {
        width: 75%;
        height: 100%;
    }

    .moreDetails {

        font-size: 10px;



    }


}
