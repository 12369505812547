.container {
    height: 100%;
    width: 100%;
    padding-top: 6rem;

    padding-bottom: 6vh;
    
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.photosDiv {
    padding: 2vh 0;
     display: flex;
     width: 70%;
     align-items: center;
     justify-content: flex-start;
 
     height: auto;
     gap: 3rem;
 
 }

 #contactOwnerId {
    background-color: rgb(28, 28, 93) !important;
    border: 3px rgb(28, 28, 93) solid;
}



.contactOwner:hover {
    background: transparent !important;
    
    color: darkgreen;
 }


 #contactOwnerId:hover {
    color: rgb(28, 28, 93) !important;
    background: transparent !important;
 }


.wrapper {

   margin-top: 3vh;
    padding-top: 3vh;
    height: 60%;
    width: 90%;
    margin-left:5vw;
    margin-right: 5vw;
    display: flex;
    align-items: flex-start;
    gap: 26px;
}







/* left */

.left {
    flex: 1;
    height: 700px;
   
   
    
    
}

.left>img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    
}

/* right */

.right {
    flex: 1;
   
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.title {
   
    font-weight: 600;
    font-size: 32px;
    color: #222;
    display: flex;
    
    align-items: center;
}

.btnDels {
    display: flex;
    justify-content: space-around;
    margin-top: 2vh;
}

.btnDel {
    border: 2px #fff solid;
    background: transparent;
    color: #fff;
    font-weight: bold;
    padding: 0.5vh 0.5vw;
    cursor: pointer;
    transition: 180ms all;
    border-radius: 3px;
}


.btnDel:hover {
    background-color: #555;

}

.banner {
    display: flex;
   
    justify-content: space-between;
}

.controls {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.controls>a {
    text-decoration: none;
    padding: 0.25rem 0.75rem;
    font-size: 16px;
    background-color: green;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.controls>button {
    outline: none;
    border: none;
    padding: 0.25rem 0.75rem;
    font-size: 16px;
    background-color: #f00;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.priceAndOwner .owner {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 0.5rem;
}

.details>.priceAndOwner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}

.priceAndOwner>.price {
    font-weight: bold;
    font-size: 18px;
}

.priceAndOwner>.price>span {
    font-weight: normal;
    font-size: 17px;
}

.moreDetails {
    display: flex;
    align-items: center;
    gap: 1.25rem;
   
}

.h4tag {
    margin-bottom: 1vh;
}

.moreDetails>span {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-right: 1px solid #222;
    color: #222;
    padding-right: 1.25rem;
}

.moreDetails>span:last-child {
    border: none;
}

.typeAndContinent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-transform: capitalize;
    padding-bottom: 2vh;
   
}

.typeAndContinent>div>span {
    background-color: transparent;
    color: #222;
    padding: 0.4rem 1rem;
    
    
    border: 2px #222 solid;
}

.desc {
    
    
    width: 100%;
    font-size: 22px;
    color: lightgray;
    margin: 1rem 0;
    text-align: left;
}

.desc>span {
    color: #222;
    font-size: 20px;
   
}


.contactOwner {
   

   
    
    background-color: darkgreen;
    color: #fff;
    border: 3px darkgreen solid;
    padding: 5px;
    margin-top: 2vh;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
    transition: 180ms all;
    width: 40%;
    height: 55%;
    border-radius: 3px;
    margin-bottom: 2vh;
    

}


.contactOwner:hover {
    background: transparent;
    
    color: darkgreen;
 }


.carousel {
    margin-top: 1vh;
    padding-top: 10vh;

    
    height: 350px;
    width: 700px;
}

/* list property */

.contactForm {
    position: absolute;
    top: 5;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 999999;
}

.contactFormWrapper {
    overflow: hidden;
    position: relative;
    background-color: #FAF9F6;
    height: 75vh;
    width: 30%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
}

.contactFormWrapper>h2 {
    width: 70%;
    text-align: center;
    margin: 0.5rem 0;
    margin-bottom: 0.5rem;
    font-size: 32px;
}

.contactFormWrapper>form {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.contactFormWrapper>form>input {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 19px;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
}

.contactFormWrapper>form>div>label {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;
    margin-right: 1rem;
}

.formDiv > form  {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.formDiv >  h2 {
    text-align: center;
    margin-bottom: 0.7vh;
}


.speshInput {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 14px;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
    width: 180px;
}



.descrip {

    width: 100%;
    margin: 2vh 0;
    height: 170px;
    font-size: 13px;
    margin-top: 1vh;
    margin-bottom: 1vh;
 
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
  }

  .formH {
    margin-bottom: 1vh;
  }




  .emailBtn {
   


    background: transparent;
    color: #444;
    border: 2px #444 solid;
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: 180ms all;
   
    border-radius: 3px;
    margin: 2vh 0;
    

}


.emailBtn:hover {
    background-color: #737373;
    color: #fff;
 }



.carWrapper {
    width: 100%;
    height: 250px;

}





.removeIcon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 22px;
    cursor: pointer;
}

.contactBookmarkControls {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.contactBookmarkControls>* {
    cursor: pointer;
}

.error {
    height: 70px;
    width: 250px;
    text-align: center;
    border-radius: 20px;
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #f00;
    color: #fff;
    position: fixed;
    top: 5rem;
    right: 5rem;
    font-weight: 500;
}



a {
    text-decoration: none;
}

.noFuncMessage {
    margin-top: 2.5rem;
    font-size: 22px;
    color: #333;
    transition: 150ms all;
}

.noFuncMessage:hover {
    color: #555;
}

.commentSection {
    margin: 0 auto;
    margin-top: 5rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #555;
    border-radius: 20px;
}



/*agent properties */

.myBox {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.property {
    height: max-content;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    width: 70px;
    height: 60px;
    border: 1px grey solid;
}

.property:hover {

    background-color: white;
    
   
     
    opacity: 0.5;

}

.property>.imgContainer {
    display: inline-block;
    height: 100%;
    width: 100%;
    
    
    
    
    
 
    
    cursor: pointer;
}

.property>.imgContainer>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    
    transition: 150ms all;
}

/* .property>.imgContainer>img:hover {
    scale: 1.035;
} */

.owner {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.4vw;
    margin-bottom: 1vh;
}

.property>.details {
    display: flex;
    flex-direction: column;
   margin-left: 0.4vw;
    padding: 0 0.5rem;
    max-height: 150px;
    width: 100%;
    min-height: 150px;
}

.property>.details>.priceAndOwner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.property>.details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.property>.details>.propName {
    width: 60%;
    
    font-size: 18px;
    margin-bottom: 0.5vh;

    
}

.property>.details> p {
    overflow: hidden;
    text-overflow: ellipsis;
}

.priceAndOwner>.price {
    font-weight: bold;
    font-size: 18px;
}

.moreDetails {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.moreDetails>span {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-right: 1px solid #fff;
    
    padding-right: 1.25rem;
}

.moreDetails>span:last-child {
    border: none;
}

.desc {
    
   
  
    font-size: 14px;
    color: grey;
}


.success {
    z-index: -5;
   
    width: 100%;
    text-align: center;
 font-size: 20px;
    
    padding: 1.5rem;
    background-color: green;
    color: #fff;
    position: absolute;
    top: 150px;
   
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms all ease-in-out;
    opacity: 0;
}


  
  .success.show {
    opacity: 1;
    z-index: 999;
  }




@keyframes oscillate {
    0% {
      color: #ddd;
    }
    50% {
      color: red;
    }
    100% {
      color: #ddd;
    }
  }
  
  .loader {
    font-size: 24px;
    animation: oscillate 1s infinite;
    margin: 30vh 0;
  }


/* RESPONSIVE */

@media only screen and (max-width: 992px) {


    .container {
        padding-top: 8rem;
    }
  

    .wrapper {

            flex-direction: column;

            padding: 2vh 2vw;

    }
    .left {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .left>img {
        width: 100%;
        height: 75%;
    }

    .right {
        width: 100%;
    }

    
    .contactFormWrapper>h2 {

        font-size: 24px;

    }

    .imgBox {
        align-items: center;
    }

    .priceAndOwner {
       
        justify-content: space-between;
    }

    .priceAndOwner > span {
        font-size: 11px;
    }
}


@media only screen and (max-width: 768px) {


    

   
    .contactFormWrapper {
    
        width: 60%;
    }

    .contactFormWrapper>form>input {
    
        width: 70%;
        font-size: 17px;
    
    
    }

    .descrip {

        width: 90%;
        font-size: 12px;
        height: 160px;

    }

    


    .contactOwner {
        width: 150px;
    }

    .banner {
        flex-direction: column;
    }
}


@media only screen and (max-width: 368px) {

    .desc>span {
        color: #222;
        font-size: 18px;
       
    }


    
   
    
    .descrip {
        margin: 2vh 0;
        
    
        font-size: 14px;
    
    }

    .contactFormWrapper>h2 {

        font-size: 22px;




    }

   
    
    
    }