footer {
    width: 100%;
    height: 100%;
    padding: 3vh 3vw;
    background-color: #222;
    color: white;
}

.wrapper {
    margin-top: 15vh;
    margin-bottom: 15vh;
    width: 74%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.col {
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 0.5rem;
}



.col> span {
    font-size: 15px;
    margin-bottom: 10px;
    justify-self: flex-start;
}

.flex {
    width: 30%;
    display: flex;
    
    align-content: flex-start;
    gap: 1rem;
    padding: 0;
    text-align: left;

   
}

.flex > a {
    color: #fff;
    width: 4vw;
    height: 4vh;
  
}

.copy {
    text-align: left;
    font-size: 16px !important;
    margin-top: 2vh;
    margin-bottom: 1vh;
    color: #888;
}

 a > .icon {
    width: 100%;
    height: 100%;
  
   
}

.link {
    cursor:pointer;
}

.link:hover {
    color: #444;
}
/* !!!!!!!! RESPONSIVE !!!!!!!! */

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    footer{
        padding-top: 50px;
    }
    
    .wrapper{
        align-items: flex-start;
        gap: 1.25rem;
        display: block;
    }
    
    .col:nth-child(2) {
        font-size: 15px;
        gap: 0.3rem;
    }
 
    .col>h2 {
        font-size: 18px;
        font-weight: 600;
    }
    
    .col>p {
        max-width: 325px;
    }
}
   
@media only screen and (max-width: 500px) {
        footer{
            padding-top: 50px;
        }
        
        .wrapper{
            align-items: flex-start;
            gap: 1.25rem;
        }
        
        .col:nth-child(2) {
            font-size: 12px;
            gap: 0.3rem;
        }
     
        .col>h2 {
            font-size: 10px;
            font-weight: 600;
        }
        
        .col>p {
            max-width: 225px;
            font-size: 15px;
        }
}