.container {
    height: 100%;
    width: 100%;
    padding-top: 5rem;
  

    background-color: #faf9f6;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.wrapper {

 

   margin-top: 7vh;
    
    height: 90%;
    width:  92%;
    margin-left:5vw;
    margin-right: 5vw;
    display: flex;
    align-items: left;
    
    gap: 20px;


    transition: opacity 2s ease-in;
}


.littlePhotos {
    width: 100px;
    height: 100px;
}






/* left */





/* right */

.right {
    flex: 1;
  
   
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 1vw;
    

}

.title {
   
    margin-bottom: 2vh;
    
    font-weight: 600;
    font-size: 32px;
    color: #222;
    display: flex;
  
  
}

.controls {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
   
}

.controls>a {
    text-decoration: none;
    padding: 0.25rem 0.75rem;
    font-size: 16px;
    background-color: green;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.controls>button {
    outline: none;
    border: none;
    padding: 0.25rem 0.75rem;
    font-size: 16px;
    background-color: #f00;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.priceAndOwner .owner {
    height: auto;
    width: 60px;
    border-radius: 50%;

  
    object-fit: cover;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  font-size: 22px;
    padding: 0 0;
    gap: 0.3rem;
    
}

.details>.priceAndOwner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vh 0;
   
   
}

.priceAndOwner>.price {
    font-weight: 500;
    font-size: 22px;

    
}

.priceAndOwner>.price>span {
    font-weight: normal;
    font-size: 22px;
}

.space {
    margin-left: 1vh;
    font-weight: 500 !important;
}

.moreDetails {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.50rem;
  
   
}

.moreDetails>span {
    display: flex;
    align-items: center;
    gap: 1rem;

    color: #222;
    padding-right: 1.25rem;
    margin-bottom: 1vh;
    font-size: 21px;
}

.moreDetails>span:last-child {
    border: none;
}

.typeAndContinent {
    display: flex;
    width: 100%;
    height: 60%;
    gap: 2vh;
    
    flex-wrap: wrap;
   margin-bottom: 1vh;
    text-transform: capitalize;
   
   
}

.typeAndContinent>div>span {
    background-color: transparent;
    color: #444;
    padding: 0.2rem 0.6rem;
    margin: 0.5vh 0;
  
    
    font-size: 14px;
    border: 2px #555 solid;
}

.bold {
    font-weight: 500;
   
}

.desc {
    
    
    width: 92%;
    display: flex;
    align-items: left;
    flex-direction: column;
    font-size: 18px;
    color: #555;
    
    margin: 1.3rem 0;
    text-align: left;
    padding-top: 1vh;
   
}


  
  







.desc>span {
    color: #222;
    font-size: 20px;
    width: 100%;
   
   
}

.imgRow {
    display: flex;
    justify-content: space-between;
}

.imgBox {
    display: flex;
    gap: 1rem;
    padding-bottom: 2vh;
    flex-wrap: wrap;
}


.contactOwner {
   

   
    position: relative;
    background-color: darkgreen;
    color: #fff;
    border: 3px darkgreen solid;
    padding: 10px;
   
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: 180ms all;
    text-align: center;
    width: auto;
    height: auto;
    border-radius: 3px;

   
   
    

}

#contactOwnerId {
    background-color: rgb(28, 28, 93) !important;
    border: 3px rgb(28, 28, 93) solid;
}


.contactOwner:hover {
    background: transparent !important;
    
    color: darkgreen;
 }

 .arrow {

    position: absolute;
    left: 8%;
    top: 20%;
    
 }


 #contactOwnerId:hover {
    color: rgb(28, 28, 93) !important;
    background: transparent !important;
 }



.contactFormWrapper>form>input {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 19px;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
}




.descrip {
    margin: 2vh 0;
    width: 100%;
    height: 150px;
    font-size: 14px;
    margin-top: 1vh;
    margin-bottom: 1vh;
  
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);

}


.photosDiv {
   padding: 2vh 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: auto;
    gap: 3rem;

}

.photosDivButton {
   
    width: 120px;
    height: 120px;
    position: relative;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    background-color: #222;
   

}



.photosDivButton:hover{
   
    scale: 1.035;
   


}


.photosDivButton > img {
    width: 100%;
    height: 100%;
    z-index: 99;
}

.energyModal {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 7px;
    padding-top: 2vh;
    
}

.energyModal > img {
    width: 400px;
  
    

}


.energyModal > h3 {
    font-size: 25px;
    margin-bottom: 2vh;
}






.stationBox {
    margin: 2vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;
    width: 92%;
    padding-bottom: 2vh;

}

.stationBox > h3 {

    padding-top: 1vh;
    font-size: 27px;
    padding-bottom: 4vh;

}

.stationItems {

    display: flex;
    justify-content: space-evenly;
    gap: 3.5rem;
}

.stationItem {
    display: flex;
}

.stationItem >  img {
    height: 30px;
    width: 50px;
}

.stationItem > span {
    padding-bottom: 1vh;
    padding-left: 0.2vw;
    font-size: 20px;
}

.carousel {
    margin-top: 1vh;
    padding-top: 10vh;

    
    height: 350px;
    width: 700px;
}


.btnDels {
    display: flex;
    justify-content: space-around;
    margin-top: 2vh;
}

.btnDel {
    border: 2px #fff solid;
    background: transparent;
    color: #fff;
    font-weight: bold;
    padding: 0.5vh 0.5vw;
    cursor: pointer;
    transition: 180ms all;
    border-radius: 3px;
}


.btnDel:hover {
    background-color: #555;

}




/* list property */

.contactForm {
    position: absolute;
    top: 5;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 999999;
}

.contactFormWrapper {
    overflow: hidden;
    position: relative;
    background-color: #FAF9F6;
    height: 75vh;
    width: 30%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 0.9rem;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
}

.contactFormWrapper>h2 {
    text-align: center;
    margin: 0.5rem 0;
    margin-bottom: 0.5rem;
    font-size: 32px;
}

.contactFormWrapper>form {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.contactFormWrapper>form>input {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 19px;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
}

.contactFormWrapper>form>div>label {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;
    margin-right: 1rem;
}



.carWrapper {
    width: 100%;
    height: 250px;

}

.emailBtn {
   


    background: transparent;
    color: #444;
    border: 2px #444 solid;
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: 180ms all;
   
    border-radius: 3px;
    margin: 2vh 0;
    

}


.emailBtn:hover {
    background-color: #737373;
    color: #fff;
 }

 .lastMinute {
    position: relative;
    z-index: 99999;
 }

.removeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 22px;
    cursor: pointer;
}

.contactBookmarkControls {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.contactBookmarkControls>* {
    cursor: pointer;
}

.error {
    height: 70px;
    width: 250px;
    text-align: center;
    border-radius: 20px;
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #f00;
    color: #fff;
    position: fixed;
    top: 5rem;
    right: 5rem;
    font-weight: 500;
}

.success {
    z-index: -5;
   
    width: 100%;
    text-align: center;
 font-size: 20px;
    
    padding: 1.5rem;
    background-color: green;
    color: #fff;
    position: absolute;
    top: 150px;
   
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms all ease-in-out;
    opacity: 0;
}


  
  .success.show {
    opacity: 1;
    z-index: 999;
  }


a {
    text-decoration: none;
}

.modalSpecial{
    background-color: #faf9f6;
}

.noFuncMessage {
    margin-top: 2.5rem;
    font-size: 22px;
    color: #333;
    transition: 150ms all;
}

.noFuncMessage:hover {
    color: #555;
}


.listPropertyForm {
    position: absolute;
    top: 140px;
    
    height: 700px;
    width: 100%;
    
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    padding-bottom: 2vh;
  
  }
  
  .listPropertyWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1877F2;
    height: auto;
    width: 70vw;
    border-radius: 20px;
    padding: 1vh 1vw;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
  }
  
  .listPropertyWrapper>h2 {
    text-align: center;
    margin: 0.2rem 0;
    margin-bottom: 0.3rem;
    font-size: 22px;
    color: #fff;
  }

  .listPropertyWrapper > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
  }
  
  
  .listPropertyWrapper>form>div>label {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #fff;
    font-size: 16px;
    margin-right: 1rem;
  }

  
form > .inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
  }

  
  .listPropertyWrapper>form>select{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #222;
    outline: none;
    padding: 0.5rem 0.25rem;
    width: 50%;
  }
  
  .listPropertyWrapper>form>input {
    font-size: 16px;
    margin: 3px;
  }
  
  .listPropertyWrapper button {
    margin-top: 0.5rem;
  }

  .scrip {

    width: 80%;
    margin-bottom: 1vh;
    height: 130px;
  }

.types {
    display: flex;
    justify-content: space-evenly;
    gap: 1.3rem;
}

.types2 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.formDiv {
    position: relative;
}

.formDiv > form  {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.formDiv >  h2 {
    text-align: center;
    margin-bottom: 0.7vh;
    font-size: 28px;
}


.speshInput {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 17px;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
    width: 180px;
}


.btnUpload2 {
    color:  white;
    display: flex;
    cursor: pointer;
    border: 2px #fff solid;
    
    
  }
  
  .btnUpload2:hover {
    color: green;
    border: 2px green solid;
  }
  
.keywords {
    display: flex;
  
    justify-content: space-evenly;
}

.formButton2 {
 
    color: white;
    width: 50%;
    margin: 7vh 0;
    background: transparent;
    border: 2px white solid;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
  }

  .photoUnit {
    display: flex;
    flex-direction: column;
  }

  .map {
    margin-top: 2vh;
    width: 92%;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
   
    transition: opacity 0.5s ease-in;
  }

  


  @keyframes oscillate {
    0% {
      color: #ddd;
    }
    50% {
      color: #999;
    }
    100% {
      color: #ddd;
    }
  }
  
  .loader {
    font-size: 24px;
    animation: oscillate 1s infinite;

    margin: 30vh 0;
  }

/* RESPONSIVE */


@media only screen and (max-width: 1402px) {
    .listPropertyForm {
        display: none;
    }


    


.wrapper {

    flex-direction: column;
    width: 90%;
    
    gap: 0px;


}


.right {

    padding-top: 2vh;
}


.arrow {

    
    left: 10%;
    top: 25%;
    
 }
.desc {
    width: 90%;
}

.map {
    width: 90%;
}


.title {
    
    margin-bottom: 1rem;

}

.utilities {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding-bottom: 1vh;
    width: 90%;
    height: 1300px;
}

.utilities > div  {
    width: 60%;
    height: 505px;
    padding: 1vh 1vw;


}


}






@media only screen and (max-width: 992px) {


    .modalSpecial {
        width: 80% !important;
       

    }

   

    .energyModal > img {
        width: 90%;
       
        
        
    
    }

   



    .wrapper {
            padding-top: 4vh;
            flex-direction: column;

    }
    .left {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 200px;
    }

    .left>img {
        width: 100%;
        height: 500px;
    }

    .right {
        width: 100%;
    }

    .stationItems {

        flex-direction: column;
        gap: 0.7rem;
        
    }

    .stationItem > span {
        font-size: 18px;
        
    }

    .stationBox > h3 {
        text-align: center;
        padding: 2vh  0;

    }

    

    .utilities > div > h3 {
        padding-bottom: 1vh;
    }


    
    .contactFormWrapper>h2 {

        font-size: 24px;

    }

    .imgBox {
        align-items: center;
    }

    .priceAndOwner {
       
        justify-content: space-between;
        gap: 2rem;
    }

    .priceAndOwner > span {
        font-size: 16px;
    }


   
}


@media only screen and (max-width: 768px) {


  

   



    .contactFormWrapper>form>input {
    
        width: 70%;
        font-size: 17px;
    
    
    }
    .contactFormWrapper {
    
        width: 60%;
    }


    .contactOwner {
        width: 150px;
        margin: 1vh 0;
        margin-top: 2vh;
       

    }

    
.utilities > div  {
    width: 90%;
  


}


.descrip {

    width: 90%;
    font-size: 12px;
    height: 160px;

}

.contactFormWrapper>h2 {

    font-size: 20px;
    width: 80%;




}

.utilities > div {
    max-height: 455px !important;




}

}

@media only screen and (max-width: 498px) {

    .energyModal > img {
        width: 90%;
        height: 300px;
        
    
    }

    .energyModal > h3 {
        font-size: 22px;
    }

    .energyModal {
        padding-top: 5vh;
    }

    .energyModal > .floorPlanImgs {
        height: 300px !important;
    }

    .modalSpecial {
       
        height: 400px !important;

    }




    .desc>span {
        color: #222;
        font-size: 18px;
       
    }

    .wrapper {
        gap: 5px;
    }

    .title {
      

        font-size: 29px;
        text-align: center;

    }
    

    .desc {
        padding-top: 0.1vh;
    }

    .typeAndContinent {
     
        gap: 2vh;
        margin: 1vh 0;
       
    }

    .typeAndContinent>div>span {
        
      
        
        font-size: 15px;
       
    }

   


.priceAndOwner>.price {
    font-weight: bold;
    font-size: 16px;
    width: 30%;
    margin-top: 1vh;
}

.moreDetails {
    margin-top: 1vh;
    display: flex;
   font-size: 16px;
   gap: 0.75rem;
   
  
    
}


.utilities > div > h3 {

  
    font-size: 26px;
    
}

.stationBox > h3 {
    font-size: 26px;
   

}



.space {
    margin-left: 0;
}

.contactFormWrapper {
    margin-top: 5vh;
}


.utilities {
    gap: 0.3rem;
    height: 800px;
    padding-top: 1vh;
}

.utilities > div {
    max-height: 355px !important;




}











}



@media only screen and (max-width: 320px) {

    .stationBox {
        padding-bottom: 0;
        margin-bottom: 0;
    }

  


    .specialSpan {
        display: none !important;  
    }
    .typeAndContinent {

        font-size: 14px;


    }

    .utilities > div {
        max-height: 295px !important;
    
    
    
    
    }

}


@media only screen and (min-height: 920px) {


    .utilities {
       
        height: 1100px;
       
    }
    
    .utilities > div {
        max-height: 495px !important;
    
    
    
    
    }

  

}


@media only screen and (min-width: 1720px) {


    
    .utilities {
       
        height: 800px;
       
    }

  

    .utilities > div {
        max-height: 695px !important;
    
    
    
    
    }


}